/*make sure to import all the other css files here for them to work in the project*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

  @font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
  }
  @font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
  }
  @font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
  }
  @font-face {
    font-family: "Poppins-SemiBold";
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
  }
  @font-face {
    font-family: "Times-Medium";
    src: url("../fonts/Times/Times-Medium.ttf");
  }
  @font-face {
    font-family: "Times-Regular";
    src: url("../fonts/Times/Times-Regular.ttf");
  }
  @font-face {
    font-family: "Times-Bold";
    src: url("../fonts/Times/Times-Bold.ttf");
  }
  @font-face {
    font-family: "Times-SemiBold";
    src: url("../fonts/Times/Times-SemiBold.ttf");
  }
  @font-face {
    font-family: "Lobster-Regular";
    src: url("../fonts/Lobster/Lobster-Regular.ttf");
  }

.lock-menu {
  left: -120px;
}

#google_translate_element{
  display: none;
}

html {
  scroll-behavior: smooth !important;
}

body {
  top: 0px !important;
}

font:hover{
  pointer-events: none !important;
}

body > .skiptranslate {
  display: none;
}

.loader {
  font-size: 20px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}
.createloader {
  font-size: 5px;
  margin-right: 15px;
  width: 5px;
  height: 5px;
  border-radius: 40%;
  position: relative;
  text-indent: -9999em;
  animation: mulShdSpin 1.1s infinite ease;
  transform: translateZ(0);
}

@keyframes mulShdSpin {
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ffffff,
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.7),
      1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.5),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.7), 2.5em 0em 0 0em #ffffff,
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.5),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.7), 1.75em 1.75em 0 0em #ffffff,
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.5),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.7), 0em 2.5em 0 0em #ffffff,
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.2),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.5),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.7), -1.8em 1.8em 0 0em #ffffff,
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.5),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.7), -2.6em 0em 0 0em #ffffff,
      -1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255, 255, 255, 0.2),
      1.8em -1.8em 0 0em rgba(255, 255, 255, 0.2),
      2.5em 0em 0 0em rgba(255, 255, 255, 0.2),
      1.75em 1.75em 0 0em rgba(255, 255, 255, 0.2),
      0em 2.5em 0 0em rgba(255, 255, 255, 0.2),
      -1.8em 1.8em 0 0em rgba(255, 255, 255, 0.5),
      -2.6em 0em 0 0em rgba(255, 255, 255, 0.7), -1.8em -1.8em 0 0em #ffffff;
  }
}

.min-cal-200-h-screen {
  height: calc(100vh - 200px);
}

.animate-line-loader {
  animation: lineLoader 180s 1;
  animation-fill-mode: forwards;
}

@keyframes lineLoader {
  0% {
    width: 0%;
  }
  100% {
    width: 95%;
  }
}

.typingEffect {
  font-family: Times, serif, Bold, sans-serif;
  font-weight: 600;
  padding-bottom: 38px;
  font-size: 36px;
  text-align: left;
  display: flex;
  justify-content: left;
  align-items: left;
  background: transparent;
}
@media (min-width: 600px) {
  .typingEffect {
    font-family: Times, serif, Bold, sans-serif;
    font-weight: 600;
    padding-bottom: 38px;
    font-size: 46px;
    text-align: left;
    display: flex;
    justify-content: left;
    align-items: left;
    background: transparent;
  }
}

.story-image-btn {
  opacity: 0;
}

.story-image:hover .story-image-btn {
  opacity: 1;
}
.overflow-y-auto::-webkit-scrollbar {
  width: 10px;
}
.overflow-y-auto::-webkit-scrollbar-track {
  background-color: white;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #e2e2e2;
  border-radius: 100px;
}


.remove-prefill-autofill:-webkit-autofill,
.remove-prefill-autofill:-webkit-autofill:hover,
.remove-prefill-autofill:-webkit-autofill:focus,
.remove-prefill-autofill:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s !important;
}

.change-prefill-text-color:-webkit-autofill {
  -webkit-text-fill-color: white !important;
}

.swal2-radio {
  display: grid !important;
}

.profile-image-wrapper {
  position: relative;
}
.profile-image-wrapper:hover {
  /* opacity: 70% !important; */
  background-color: #0b0b0b;
}

.profile-image-wrapper:hover .camera-icon {
  display: block;
}

.camera-icon {
  position: absolute;
  display: none;
  top: 45px;
  left: 52px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

@media (max-width: 768px) {
  .lock-menu{
    left: -50px;
  }
}

video::-webkit-media-controls {
  width: 20vw;
}
video::-webkit-media-controls-enclosure {
  width: 100%;
  position: relative;
  overflow: visible;
}
video::-webkit-media-controls-panel {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0px;
}
@media screen and (min-width: 1000px) and (max-width: 2401px) {
  video {
    height: 85vh !important;
  }
}
.create-book::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  display: none;
}

/* Track */
.create-book::-webkit-scrollbar-track {
  background: #888;
}

/* Handle */
.create-book::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 5px;
  min-height: 50px;
}

/* Handle on hover */
.create-book::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.image-loader {
  border: 5px solid #6c6c6c;
  border-radius: 50%;
  border-top: 5px solid #ff2a29;
  animation: spin 1s linear infinite;
}

.profile-image-loader {
  border: 3px solid #6c6c6c;
  border-radius: 50%;
  border-top: 3px solid #ff2a29;
  animation: spin 1s linear infinite;
}

.alert-toast {
  height: 68px;
  padding: 23px 22px 22px 18px;
}

.slider {
  -webkit-transition: 0.2s;
  transition: 0.2s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 25px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.2s;
  transition: 0.2s;
  border-radius: 50%;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

.truncate-line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.lang-dropdown-scrollbar {
  scrollbar-width: auto;
  scrollbar-color: #ffffff #212121;
}

.lang-dropdown-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.lang-dropdown-scrollbar::-webkit-scrollbar-track {
  background: #212121;
}

.lang-dropdown-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ffffff;
  border-radius: 10px;
  border: 0px solid #ffffff;
}

@media (max-width: 768px) {
  .creating-your-story-container{
    width: 100% !important;
  }

  .camera-icon {
    top: 28px;
    left: 35px;
  }
}

